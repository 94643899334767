import React from 'react'
import styled from 'styled-components'

class Services extends React.Component {
    render() {
        return (
            <div className={`section ${this.props.className}`}>
                <div className="info">
                    Non puoi venire a trovarci? Nessun problema!
                    <br />
                    Ordina la tua pizza preferita online! 🍕
                </div>
                <div className="info">
                    <a
                        href="https://glovoapp.com/it/en/laquila/paria-pizzeria-partenopea-qaq"
                        target="_blank"
                        rel="noopener noreferrer">
                        <button className="menu-k1s">
                            👉 Ordina su Glovo
                        </button>
                    </a>
                    <a
                        href="https://deliveroo.it/it/menu/laquila/laquila/paria-pizzeria-partenopea"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="menu-k1s">
                            👉 Ordina su Deliveroo
                        </button>
                    </a>
                </div>

                <div style={{ height: '40px' }}></div>

                <img className="hotbox-4lk" src="https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/gallery/delivery-scooter.jpg" alt="hotbox"></img>

                <div className="info last">
                    <div>
                        <a href="tel:3347619116">+39 334 7619116</a>{' '}
                    </div>
                </div>
            </div>
        )
    }
}

const StyledContacts = styled(Services)`
    background-image: url('https://paria-web-store.s3-eu-west-1.amazonaws.com/site-assets/paria_pattern2.jpg');
    &.section.fp-section.fp-table {
        background-color: #fdf6ec;
    }
    .logo {
        height: 200px;
        width: 200px;
    }
    .info {
        text-align: center;
        font-family: RecoletaAlt;
        padding: 10px 30px;
        font-size: 21px;
        max-width: 500px;
        margin: 0 auto;
        a {
            text-decoration: underline;
        }
        @media (max-width: 900px) {
            font-size: 16px;
        }
        &.last {
            margin-bottom: 5vh;
        }
    }
    .menu-k1s {
        padding: 5px 20px;
        cursor: pointer;
        margin: 4px 10px;
        background-color: #ffffff;
        border: 2px solid #91d147;
        font-weight: bold;
        text-transform: uppercase;
        color: #91d147;
        &:hover {
            background-color: #91d147;
            color: white;
        }
    }
    .hotbox-4lk {
        height: 30vh;
        margin: 0 auto;
        display: flex;
    }
    .fp-tableCell {
        vertical-align: bottom;
    }
`

export default StyledContacts
